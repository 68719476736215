@import "vars";
@import "~select2";
@import "~bootstrap/scss/bootstrap.scss";
@import "mixins";
@import "specific";
@import "forms";
@import "options";
@import "modals";
@import "colors";
@import "dropdown";
@import "about-us";
@import "animations";
@import "badges";
@import "position";
@import "text";
@import "vue";
@import "../../css/tinymce_templates.css";

// Global
/////////
header.no-sticky {
  position: relative;
}
h1 {
  color: $azure-blue;
  font-weight: 700;
  line-height: normal;
}

div + h2 {
  margin-top: 2rem;
}

button:hover {
  cursor: pointer;
}

// For attach footer to bottom
.flex-body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-of-type {
    min-height: 100vh;
  }
}

a {
  color: $electric-blue;
  &:hover {
    color: $electric-blue;
  }
}

// New-Header 2025
/////////
#header {
  margin-bottom: $menuheader-desk-height;
  @include media-breakpoint-down (lg) {
    margin-bottom: $menuheader-mobile-height;
  }
  #navbar-mobile {
    li.nav-job, .job-content {
      background-color: #DCEFF1;
    }
    .job-content {
      border-radius: 6px;
      padding: 20px 12px;
    }
    #page-header-light {
      @include media-breakpoint-up (md) {
        height: $menuheader-mobile-height;
      }
      border-bottom: 1px solid #00000020;
    }
  }

  li.space-pro, .box-unbounce {
    background-color: #001835;
    a {
      color: white !important;
    }
  }

  li.btn-disconnect {
    button.page-link {
      &:hover {
        background-color: transparent;
      }
    }
  }

  #menu-icon {
    background-color: $testimony-blue;
    border-radius: 200px;
    border: none;
    padding: 1rem;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    height: 50px;
    width: 50px;
    margin-left: 25px;
  }

  .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: white;
    transition: .3s;
  }

  .icon-bar + .icon-bar {
    margin-top: 5px;
  } 

  #menu-icon:not(.collapsed) .icon-bar:nth-of-type(1) {
    transform: translate3d(0,8px,0) rotate(45deg);
  }

  #menu-icon:not(.collapsed) .icon-bar:nth-of-type(2) {
    opacity: 0;
  }

  #menu-icon:not(.collapsed) .icon-bar:nth-of-type(3) {
    transform: translate3d(0,-8px,0) rotate(-45deg);
  }


  #nav-content {
    margin-top: 68px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width:100%;
    background: #00000060;
    
    .list-item-menu {
      position: absolute;
      top: 0;
      left: -900px;
      width: 85%;
      height: 100%;
      background: #f8f9fa;
      transition: left .3s ease-in-out; 
      ul.navbar-nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100svh - #{$menuheader-mobile-height});
      }
    }
    .search-link {
      border: 1px solid #7E84A3;
      border-radius: 6px;
      padding: 1rem;
      color: #7E84A3;
      width: 100%;
    }
  }
  #nav-content.show {
    .list-item-menu {
      left: 0;
    }
  }
  
  a.navmain-sm-link {
    color: black;
    width: 100%;
    height: 100%;
    padding: 1rem;
    &:hover {
      color: #2653FB80;
      transition: color .2s;
    }
  }

  .list-item-menu li {
    border-bottom: 1px solid #7E84A320;
    display: flex;
    justify-content: space-between;
    a.navbar-toggler {
      font-weight: 600;
    }
  }
  #submenu-searchform {
    input {
      width: 100%;
      border: none;
      background-color: white;
      border-radius: 6px;
      padding: 1rem 1rem 1rem 2.75rem;
    }
    .aladom-autocomplete-subcategory, .aladom-autocomplete-city {
      margin-bottom: 1rem;
    }
    .subcategory-search-input, .city-search-input {
      border: 1px solid #00000060;
    }
    .city-search-input {
      max-width: 100%!important;
    }
  }
  ul.submenu {
    margin-top: 68px;
    position: fixed;
    z-index: 2;
    top: 0;
    left: -900px;
    background-color: #ececec;
    height: 100%;
    width: 85%;
    transition: left .4s ease-in-out; 

    .submenu-header {
      height: $submenuheader-height;
      background-color: $testimony-blue;
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        color: white;
      }
      .back {
        padding: 8px 12px;
        background-color: #D9E4FF;
        border-radius: 4px;
        border: none;
        color: $testimony-blue;
        &:hover {
          background-color: #ECF2FF;
        }
      }
    }
    .navbar-toggler {
      font-size: .875rem;
      border: none;
      border-bottom: 1px solid #7E84A320;
    }
    .submenu-content, .scrollable-content {
      height: calc(100vh - #{$allmenus-height});
    }
    .submenu-content {
      display: flex;
      flex-direction:  column;
      justify-content: space-between;
    }
    .scrollable-content {
      overflow-y: scroll;
      hr {
        border: 1px solid #7E84A325;
      }
    }
    .faq-link, .city-link {
      padding: 1.25rem 0;
      a {
        color: black;
        line-height: normal;
        &:hover {
          color: #5A607F;
        }
      }
    }
    .city-link {
      a {
        font-weight: 600;
      }
    }
  }

  ul.submenu.show {
    left: 0;
  }

  a, a:visited, a:focus, a:active, a:link {
  text-decoration: none;
  outline: 0;
  }


  a.navbar-toggler {
    border: none;
    font-size: default;
    font-weight: 600;
    font-size: .875rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  span {
    color: black;
    font-weight: 600;
    transition: .2s ease-in-out;
  }

  ul {
    padding: 0;
    list-style: none;
  }
  
  nav {
    position: fixed;
    width: 100%;
    z-index: 1001;
    background-color: #F8F9FA;

    #navbar-test::after, #navbar-test::before {
      display: block;
    }

    #navbar-test::after {
      clear: both;
    }

    #navbar-test {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $menuheader-desk-height;
      position: relative;
      z-index: 1001;
      @include media-breakpoint-down (sm) {
        flex-direction: column;
        height: auto;
      }

      section .nav-items {
        width: 100%;
      }

      .logo-aladom-colored {
        background-image: url('/ui/static/img/logotype-couleur.png');
        height:45px;
        width:170px;
        background-size: cover;
        @include media-breakpoint-down(sm) {
          height: 30px;
          width: 120px;
        }
        @media (min-width: 1230px) {
          margin-left: 1rem;
        }

        &:hover {
          filter: brightness(85%);
        }

        .sticky & {
          width: 50px;
        }
      }

      .nav-items>ul {
        .show-header-search-form {
          &:hover {
            color: white;
          }
        }
        .aladom-search-form {
          display: none;
          top: 92px;
          border-radius: 12px;
          padding: 2rem;
          background-color: #f8f9fa;
          #header-search-form {
            gap: 12px;
            display: flex;
            align-items: center;
            input {
              border: none;
              padding: 1rem 1rem 1rem 2.75rem;
              background-color: white;
              border-radius: 6px;
            }
            .subcategory-search-input {
              .aladom-autocomplete-prefix-icon {
                color: $testimony-blue;
              }
            }
            .aladom-autocomplete-subcategory, .aladom-autocomplete-city {
              margin-bottom: 0;
            }
          }
        }
        .navmain-item {
          background-color: white;
          border: 1px solid #00000010;
          border-radius: 100px;
        }
        li {
          float: left;
          height: 100%;
          list-style-type: none;
          line-height: 3rem;
          padding: 0 1rem;
          border-radius: 100px;
          font-weight: 600;

          &:hover {
            background-color: $testimony-blue;
            a.navbar-item {
              color: white;
              text-decoration: none;
            }
          }
          a.navbar-item {
            color: black;
            display: block;
            height: 100%;
            width: 100%;
          }
        }
        a.tag-service {
          padding: 0px 12px;
        }

        .nav-job {
          background-color: #DCEFF1;
        }

        button.btn-connect {
          padding: 0 1rem;
          background-color: $testimony-blue;
          color: white;
          &:hover {
            border: 1px solid $testimony-blue;
            background-color: white;
            color: $testimony-blue;
          }
        }
      }
      
      .nav-items>ul {
        li:hover>a+.submenu-container {
          opacity: 1;
          visibility: visible;
          top: 65px;
          transition: all 0.2s ease-out;
        }
      }

      .dropdown-menu {
        border-radius: 12px;
        color: black;
        margin-top: 2rem;
        padding: .5rem;
        .dropdown-item {
          padding: .5rem 1rem;
          transition: all 0 linear;
          border-radius: 4px;
          font-size: .875rem;
          &:hover {
            background-color: #BDCCFE;
          }
        }
      }

      .dropdown-menu-header {
        background-color: #ECF2FF;
        border-radius: 6px;
      }
    }
  }
  .circle-img {
    border-radius: 50%;
    width: 35px;
  }

  ul#submenu-othercategories {
    a.tag-service {
      padding: 8px 13px;
    }
  }
  .list-tag-services {
    a.tag-service {
      background-color: white;
      border-radius: 4px;
      display: inline-block;
      margin: 0 0 6px;
      border: 1px solid #D7DBEC;
      color: $testimony-blue;
      text-decoration: none;
      &:hover {
        background-color: #F5F6FA;
      }
    }
  }

  .submenu-container {
    width: 100%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0px;
    background-color: #F8F9FA;
    padding: .75rem;
    opacity: 0;
    visibility: hidden;
    border-bottom: 1px solid #00000020;
    z-index: -1;

    .content-left {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .submenu-lessons {
      a.teaching-job {
        display: flex;
        align-items: center;
        text-align: end;
        line-height: normal;
      }
    }

    .job-row {
      p {
        line-height: 1.6;
      }
      img {
        height: 250px;
      }
    }
    .job-content {
      background-color: #DCEFF1;
      border-radius: 12px;
    }
    .col-seniors {
      height: 100%;
      .bg-company {
        background-color: #001835;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        border-radius: 12px;
        height: 100%;
      }
      color: white;
    }
  }
  .service-content {
    background-color: #D9E0FB;
    border-radius: 12px 400px 12px 12px;
  }
  i.ala-arrow-right {
    font-size: 1.5rem;
    font-weight: bold;
    vertical-align: middle;
  }
}

.nav-search-mobile {
  .input-search {
    display: flex;
    align-items: center;
    width: 85%;
    border-radius: 4px;
    padding: 8px 16px;
    border: 1px solid #D9E1EC;
    input {
      background-color: unset;
      border: none;
      :focus-visible {
        border: none;
      }
    }
  }
}

.btn-2K25 {
  border-radius: 4px;
  background-color: #1E5EFF;
  color: white;
  font-weight: 500;
  padding: 8px 13px;
  height: 48px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s linear;
  &:hover {
    background-color: #4F81FF;
    color: white;
  }
  &-black {
    background-color: #001335;
  }
  i.ala-arrow-right {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.btn-2K25-white {
  border-radius: 4px;
  background-color: white;
  color: #4F81FF;
  border: 1px solid #D7DBEC;
  font-weight: 500;
  padding: 8px 13px;
  height: 48px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s linear;
  &:hover {
    background-color: #F5F6FA;
    color: #4F81FF;
  }
  i.ala-arrow-right {
    font-size: 1.5rem;
    font-weight: bold;
  }
}


// Header
/////////
#page-header {
  body:not(.myaladom):not(.unbounce-form) & {
    @include full-header-no-filters();
  }
  .navbar-toggler {
    @include media-breakpoint-down(md) {
      background-color: $black;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 100%;
      width: 10%;
      color: white;
      font-size: .7rem;
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-collapse {
      background-color: white;
      text-align: center;
      position: absolute;
      width: 100vw;
      height: 100vh;
      right: 0;
      left: 0;
      top: calc(100%);
      z-index: 100;
    }
    .navbar-brand img.logo-full {
      @include media-breakpoint-down(xs) {
        div.box-white p {
          font-size: .9rem;
        }
      }
    }
  }

  .circle-img {
    border-radius: 50%;
    width: 2rem;
  }

  &:not(.home) li:not(.aladom-home-item) .nav-link {
    background-color: white;
  }

  .nav-link {
    body:not(.main-offer-list) & {
      color: $black;
    }
    font-weight: bold;
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: inline;
    }

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }
  }

  #page-header-full {
    display: none;
    -webkit-transition: padding .6s;
    -moz-transition: padding .6s;
    transition: padding .6s;
    span {
      background: transparent;
    }
    .col-8 {
      justify-content: center;
    }
    .not-home & {
      border-bottom: 1px solid $pale;
      padding-bottom: 1rem;

      .navbar-light {
        padding-bottom: 0;
        padding-right: 0;
      }
    }
  }

  #page-header-light {
    width: 100%;
    height: 100%;
  }

  #page-header-full-user-box {
    justify-content: end;
    padding: 0;
    .myaladom & {
      flex: 0 0 13%;
    }
    a, button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      padding: 1rem 2.5rem;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        color: #FFFFFF50;
      }
    }
    .dropdown-menu {
      .dropdown-item:active {
        color: white;
      }
      a {
        color: black;
        padding: 0.5rem;
        &:hover {
          color: #00000060;
        }
      }
    }
    .btn-space-pro {
      background-color: $black;
      border-radius: 0;
      width: 100%;
    }
    .btn-user-profil {
      background-color: $testimony-blue;
      border-radius: 0;
      height: 100%;
    }
    .btn-connect, .nav-link {
      background-color: $testimony-blue;
      height: 100%;
      font-size: 2rem;
    }
    .btn-connect {
      border: none;
      width: 100%;
    }
    & > .box {
      padding: 0;

      .user-name-header {
        margin: 0.5rem 0 0.5rem;
        font-weight: 700;
        font-size: .875rem;
      }
      .dropdown-menu {
        width: 100%;
        color: $black;
        font-size: .875rem;
        background-color: $gray-200;
        box-shadow: $default-shadow;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  &.sticky {
    .navbar-search-container {
      width: 160px;
    }
  }

  &:not(.sticky) {
    .navbar-search-container {
      @media (max-width: 1550px) {
        width: 160px;
      }
      width: 320px;
    }
  }

  .navbar-search-container {
    transition: width .3s;
    display: flex;
    &.active {
      width: 100%;
      .navbar-search {
        width: 95%;
        @include media-breakpoint-down(md) {
          width: calc(100vw - 210px);
        }
        @include media-breakpoint-down(xs) {
          width: 60%;
        }
      }
    }
  }

  .navbar-search {
    position: absolute;
    right: 0;
    top: 5px;
    width: 60px;
    transition: width .3s;

    input[type=text] {
      width: 100%;
      padding: 15px 20px;
      cursor: pointer;
      transition: all .3s;

      &.city-search-input {
        padding: 15px 38px;
      }
    }

    i.search_step_1 {
      @include search-icon();
      right: 20px;
      cursor: pointer;
    }

    .category-input-result, .city-search {
      padding: 2rem !important;
      min-width: 450px;
      z-index: 100;
      position: absolute;
      @include media-breakpoint-down(xs) {
        min-width: 100%;
        left: 0;
        position: fixed !important;
      }
    }

    input:not(:focus)::placeholder {
      color: transparent !important;
    }
  }

  #page-header-light .navbar-search {
    right: 120px;
    top: calc(50% - 26px);
  }

  .logo-aladom-colored {
    background-image: url('/ui/static/img/logotype-couleur.png');
    height:66px;
    width:234px;
    @include media-breakpoint-down(sm) {
      height: 30px;
      width: 120px;
    }
    background-size: cover;
    &:hover {
      filter: brightness(85%);
    }
    .sticky & {
      width: 50px;
    }
  }
}

@include media-breakpoint-up(lg) {
  #page-header {
    .navbar-nav {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      @media (max-width: 1200px) {
        max-width: 600px;
      }

      .nav-item {
        display: inline-block;
        margin: .3rem 0;
        white-space: nowrap;
        max-width: 220px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;

        &:hover {
          z-index: 10;
          overflow: visible;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #page-header {
    .nav-link {
      font-weight: 600;
      padding: 1rem;
    }
    .nav-item {
      align-content: center;
      &.highlight {
        button {
          border: 2px solid $testimony-blue;
          width: fit-content;
          padding: 1rem;
        }
      }
      &.aladom-pro-item {
        background-color: #001835;
        .nav-link {
          color: $white !important;
        }
      }
      &.aladom-home-item {
        background-color: $testimony-blue;
        button, a {
          color: white !important;
          &:hover {
            color: white;
          }
        }
      }
      &.btn-disconnect {
        background-color: #BDCCFE;
      }
      &.btn-space-pro {
        background-color: $black;
        border-radius: 0;
        .nav-link {
          color: white;
          &:hover {
            color: #00000060;
          }
        }
      }
    }
    .navbar-nav {
      width: 100%;
    }

    .page-link:hover {
      background-color: transparent;
    }
  }
}

// Sticky header
////////////////
header.container-fluid.sticky {
  padding: 0;
}

#page-header.not-home #page-header-full {
  display: block;
  position: relative;
  transition: .8s;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

#page-header.sticky {
  @include sticky-base;
  display: block;
  padding-bottom: 0;

  .navbar {
    padding-top: .5rem;
  }

  .user-name-header {
    display: none;
  }
}

#page-header.sticky #page-header-full/*, #pro-header.sticky*/ {
  @include sticky-header-full;
}

@include media-breakpoint-up(lg) {
  #page-header {
    #page-header-light, .dfp-light { display: none; }
    #page-header-full, .dfp-full { display: block; }
  }
}

.dfp-bloc {
  text-align: center;
  margin: 1rem auto;
  width: 728px;
  //height: 90px;
  @include media-breakpoint-down(sm) {
    width: 320px;
    height: 50px;
  }
}

@include media-breakpoint-up(lg) {
  #page-header #page-header-full-user-box {
    display: block;
  }
}

.quotation-bloc {
  p {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

// Banners
//////////
.banner {
  background-color: $off-white;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.subcategory-banner {
    background-color: $white;
    padding-top: 0;
    padding-bottom: 1rem;

    h1 {
      color: unset;
      font-weight: bold;
      font-size: 1.8rem;
    }

    .offer-search {
      border: unset;
    }
  }

  .lead {
    font-size: $h2-font-size;
    font-weight: 600;
  }

  .hero-image {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
  }

  &-logo {
    max-width: 250px;
    margin: 1rem;
    @include media-breakpoint-up(md) {
      float: left;
    }
  }
  &.red-gradient {
    background-image: $red-gradient;
  }
  &.blue-gradient {
    background-image: $blue-gradient;
  }
  &.blue-gradient, &.red-gradient, &.green-gradient {
    @include banner();
    p {
      font-style: italic;
    }
  }
  .offer_icon {
    font-size: 40px;
    background: white;
    padding: 15px;
    border-radius: 50%;
  }

  &.service-header {
    background-image: linear-gradient(90deg,$electric-blue,$electric-blue 160%,$electric-blue) !important;

    i, .choice-box.active {
      color: $electric-blue;
    }
  }

  &.job-header {
    background-image: linear-gradient(90deg,$cyan,$cyan 160%,$cyan) !important;

    i, .choice-box.active {
      color: $cyan;
    }
  }
  &.part-emp-banner {
    background-color: $cyan;
    @include banner();
    padding-bottom: 6rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      text-align: left;
      padding: 3rem 0;
    }

    p {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6;
    }
  }
}

//Hero
/////////
.hero {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: $medium-grey;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-height: 650px;
    max-height: 740px;
    clip-path: ellipse(125% 100% at 50% 0%);
    -webkit-clip-path: ellipse(125% 100% at 50% 0%);
    height: 150vh;
  }

  & > figure {
    margin-bottom: 2.5rem;
    height: 100vh;
    filter: brightness(30%);
    position: absolute;
    width: 100%;
    background-size: cover;
    background-position: center;
    min-height: 800px;
  }

  img.hero-image {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
  }

  &-box {
    margin: 0 auto;
    width: 100%;
    background-color: $azure-blue;
    left: calc(50% - 400px);
    padding: 2rem;

    @include media-breakpoint-up(lg) {
      width: 930px;
      left: calc(50% - 465px);
    }

    @include media-breakpoint-only(md) {
      width: 800px;
    }

    @include media-breakpoint-up(md) {
      background-color: transparent;
      position: absolute;
      bottom: 0;
      padding: 1rem 1rem 90px;
    }

    h1 {
      color: $white;
      margin-bottom: 1rem;
    }

    p {
      color: white;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.39;
    }

    &-heading {
      text-align: center;
      margin: 0 auto;
    }
  }
}

// Footer
/////////
#page-footer {

  &.no-footer {
    padding: 0;
    display: none;
  }
  padding: 2rem 1rem;
  text-align: center;
  background-color: $black;
  color: $white;
  position:relative;

  & .row > div {
    @include media-breakpoint-down(md) {
      padding-top: 1rem;
    }
  }

  .rectangle-gris {
    width: 300px; /* Largeur du rectangle */
    height: 60px; /* Hauteur du rectangle */
    background-color: $black; /* Couleur de fond gris */
    border-radius: 5px; /* Rayon de courbure des bords */
    border: 1px solid white;
  }

  .ml-10 {
    margin-left: 5rem;
  }

   .mr-10 {
    margin-left: 3rem;
  }

  .footer-line {
    border-top: 2px solid white;
    width: 100%;
  }

  span {
    color: white;
    font-weight: bold;
  }

  a {
    color: white;
  }

  button {
    color: white;
    text-transform: none;
  }

  .footer-col {
    text-align:left;
    span {
      font-size:20px;
    }
    a {
      &:hover {
        transition: all 100ms linear;
        text-decoration: none;
        color: #FFFFFF50;
      }
    }
    div.mt-3.mb-3 {
      div {
        @include media-breakpoint-down(md) {
          margin-bottom: .8rem;
        }
      }
    }
    .social-medias-btn {
      span {
        font-size: unset;
        cursor: pointer;
        color: black;
        background-color: #d9e0fb;
        &:hover {
          color: $testimony-blue;
        }
      }
    }
    .btn-testimony {
      text-transform: unset;
    }
  }

  hr {
    border-top: 1px solid $body-bg;
    max-width: 700px;
  }
  #footer-logo {
    @include media-breakpoint-down(sm) {
      order: 1;
    }
    .logo-aladom {
      display: block;
      margin: auto;
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(xl) {
        height: 56px;
        width: 200px;
        margin-bottom: 4rem;
        margin-left: 1rem;
      }

      &:hover {
        filter: brightness(85%);
      }
    }
  }

  #page-footer-links {
    text-align: left;
    position: relative;
    z-index: 1;
    padding-left: 14rem;

    @include media-breakpoint-down(sm) {
      order: 2;
      margin-top: 0 !important;
      padding: 0 15px !important;
    }
    @include media-breakpoint-down(md) {
      text-align: right;
    }

    a, button {
      color: $white;
      display: inline-block;
      padding: 0;
      letter-spacing: -0.4px;
      font-weight: 600;
      @include media-breakpoint-down(sm) {
        padding-top: 7px;
      }
    }

    button {
      border: none;
    }
  }

  .aladom-rights {
    font-size: .7rem;
  }

  .section-separator {
    position: relative;
    margin-right: -15px;
    margin-left: -15px;

    img {
      bottom: 0;
      max-height: 200px;
      max-width: 2000px;
      transform: translate(-50%, 0);
      left: 50%;
    }
  }
}

// Footer subcategories
/////////
.footer-subcategories {
  width: 100%;
  max-width: 1400px;
  padding: 2rem;
  @include media-breakpoint-up(xl) {
    padding: 4rem 11rem;
  }
  background-color: rgba($off-white, 1);
  box-shadow: 0px 0px 20px #00000043;
  border-radius: 20px;
  margin-top:6rem;
  margin-bottom: 9rem;

  h3 {
    font-weight: 1000;
    font-style: initial;
    color: $electric-blue;
    text-align: center;
    font-size: 1.9rem;
    margin-bottom: 3.95rem;
  }

  p {
    color: $black;
    text-align:center;
    font: normal normal normal 12px/17px Montserrat;
    letter-spacing: 0px;
    padding: 0;
    @include media-breakpoint-up(lg) {
      padding: 0rem 7rem;
    }
  }

  .footer-subcategory a {
    color: $black;
    display: block;
    padding: .5rem 0;
    text-align: left;
    font: normal normal 300 15px/40px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    line-height: 1;
    &:hover {
      color: $electric-blue;
    }
  }
}

// Content
//////////
.text-black {
  color: $black;
}

// Sections
/////////
.section {
  margin-bottom: 2rem;
}


// Tags
//////////
.tags-cloud {
  text-align: left;
  overflow-y: auto;

  .badge {
    margin: 0 1.875rem 1.375rem 0;
    font-weight: normal;
    font-size: .75rem;
    line-height: $line-height-sm;

    &.ice-blue {
      margin: 0 .625rem .625rem 0;
    }
  }
}

.cloud-item, .cloud-item a {
  color: $black;
  background-color: $testimony-blue-op20;
  text-transform: lowercase;
  border-radius: 3rem;
  padding: 1rem;

  &.ice-blue {
    background-color: $ice-blue;

    &:hover {
      background-color: darken($ice-blue, 10%);
    }
  }

  &:hover {
    text-decoration: none;
    color: $black;
    background-color: $pale-darker;
  }
}

// Boxes
////////
.box {
  padding: 1rem;

  &.box-rounded {
    border-radius: 15px;
  }
  &-rounded-20 {
    border-radius: 20px;
  }
}

.box-white {
  background: $white;
}

.box-gray {
  background: $off-white;
}
.box-gray-platinum {
  background-color: $grey-platinum;
}
.box-gradient, .box-white {
  color: $black;
  h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
    color: $black;
  }
}
.box-form {
  padding: 1rem;
  @include media-breakpoint-down(sm) {
    padding: 2rem !important;
    h2 {
      margin-top: 0!important;
    }
    .form-group {
      margin-bottom: 1rem;
    }
  }
  border-radius: 15px;
  margin: 1rem 0;
  @extend .box-gray;
  @extend .p-5;
}

.box-gradient {
 @include gradient-box();

  .heading {
    font-style: italic;
    font-size: 1.375rem;
    font-weight: 500;
  }
}

.box-green {
  background-color: $green;
  color: $black;
  h3, .h3 {
    font-size:2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
      margin-bottom: 1.2rem !important;
    }
  }
  p {
    font-size:1.25rem;
    @include media-breakpoint-down(sm) {
      font-size: 0.875rem;
    }
  }
}

.box-blue {
  background-color: $electric-blue;
  color: $white;
}

.box-part-emp {
  color: $black;
  background-color: rgba($part-emp-color, .12);
}

.box-presta {
  color: $black;
  background-color: rgba($presta-color, .12);
}

.box-pro {
  color: $white;
  background-color: rgba($pro-blue, .12);
}

// Cards
//////////
.list-item-card {
  background-color: $light-grey;
  padding: 3rem;
  position: relative;
  font-size: 0.95rem;

  @include media-breakpoint-down(lg) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include media-breakpoint-down(md) {
    padding: 2.5rem .5rem 1rem .5rem !important;
  }

  &.no-arc {
    padding: 3rem;
  }
  &-payment {
    background: linear-gradient(125deg, rgba(44,88,241,1) 0%, rgba(104,163,188,1) 50%, rgba(149,220,148,1) 100%);
    color: white;
    .h2 {
      font-size: 1rem;
    }
    .box-white {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      align-items: center;
      justify-content: center;
      display: flex;
      i {
        font-size: 2rem;
      }
    }
  }
  &-unbounce {
    background: #001835;
    color: white;
    .h2 {
      margin-top: 6rem;
      @include media-breakpoint-down (sm) {
        margin-top: 3rem;
        font-size: 1.5rem;
      }
      @include media-breakpoint-down (lg) {
        font-size: 1.25rem;
      }
    }
  }

  > a {
    color: inherit;
    text-decoration: none;
  }

  h3, div.h3 {
    font-size: 1.1rem;
    color: $black;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: bold;
    line-height: 20px;

    > a {
      color: inherit;
      text-decoration: none;
    }
  }

  p {
    color: $black;
    font-size: .875rem;
    letter-spacing: -.4px;
  }

  .city-badge {
    display: inline;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background-color: $sapphire;
    font-size: .625rem;
    font-weight: 600;
    padding: .2rem .5rem;
    border-radius: 4px;
    text-transform: uppercase;

    i {
      font-size: .8rem;
    }
  }

  .partner-badge {
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-size: .625rem;
    font-weight: 600;
    opacity: 0.6;
    padding: .2rem .5rem;
    border-radius: 4px;
    width: 100px;
  }

  .items-list {
    font-weight: 600;
    text-align: center;
    font-size: .875rem;
    letter-spacing: -.5px;
    color: $black;

    i {
      color: $black;
    }

    .item-date {
      font-weight: normal;
    }

    .rating-stars {
      width: 100px;
    }
  }

  .similar-badge {
    position: absolute;
    bottom: 40px;
    right: 0;
    width: 100%;
    text-align: center;
    z-index: 2;

    a {
      color: $black;
      background-color: $pale;
      text-transform: uppercase;
      border-radius: .375rem;
      font-size: .57rem;

      &:hover {
        text-decoration: none;
        background-color: darken($pale, 10%);
      }
    }
  }

  figure {
    @include offer-provider-picture(180px);
    margin: auto;

    @include media-breakpoint-up(md) {
      @include offer-provider-picture(130px);
    }
    @include media-breakpoint-down(md) {
      @include offer-provider-picture(100px);
    }
  }


  img.card-img {
    display: block;
    margin: auto;
    text-align: center;
    position: absolute;
    height: 100%;
    width: auto;
    right: 0;
    left: 0;

    &.card-img-pro {
      width: 125px;
      height: auto;
      top: 50%;
      bottom: 50%;
    }
  }

  img.card-img-part {
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  img.card-logo {
    display: block;
    margin: auto;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
    width: auto;
  }

  .card-badge {
    position: absolute;
    top: -2px;
    left: -2px;
  }

  // Fixes a bug with long words
  overflow: hidden;
  p, h3 {
    overflow: hidden;
  }

  &.white-card {
    background-color: $white;
  }
}

.badge-offer {
  display: inline;
  top: 0;
  font-size: .825rem;
  font-weight: 600;
  padding: 4px 15px;
  @include media-breakpoint-down(sm) {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
  }
  i {
    font-size: .8rem;
  }
  &-pro {
    left: 0;
    background-color: #BDCCFE;
    color: $electric-blue;
    border-radius: 0 0 20px 0;
    @include media-breakpoint-down(md) {
      border-radius: unset;
    }
  }
  &-payment {
    right: 0;
    background-color: $checked-identity-color;
    color: $black;
    border-radius: 0 0 0 20px;
    @include media-breakpoint-down(md) {
      border-radius: unset;
    }
  }
}

.job-banner {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.custom-list-item-card {
  border-radius: 20px;
  margin-bottom: 1rem;
  width: 100%;
}
#myaladom-user-card {
   min-height: 150px;
}
.custom-list-item-card, #myaladom-user-card {
  .single-line-title {
    white-space: nowrap;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      margin-right: 1rem;
    }
  }
  &.blue-border {
    border: 2px solid $blue;
  }
  figure {
    @include offer-provider-picture(180px);
    border: 2px solid $blue;
    margin: auto;
    &.offer-figure, &.candidacy-figure {
      @include media-breakpoint-down(lg) {
        width: 160px;
        height: 160px;
      }
      @media (max-width: 1000px) {
        width: 140px;
        height: 140px;
      }
    }
    &.no-border {
      border: unset;
    }
  }
  img.card-img {
    display: block;
    margin: auto;
    text-align: center;
    position: absolute;
    height: 100%;
    width: auto;
    right: 50%;
    left: 50%;
    transform: translate(-50%,0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);

    &.card-img-pro {
      width: 125px;
      height: auto;
      top: 50%;
      bottom: 50%;
      @include media-breakpoint-down(md) {
        width: 80% !important;
      }
    }
  }
}
.filter-choice-card {
  border-radius: 20px;
  box-shadow: 0 0 30px #00000029;
  p.filter-title {
    font-weight: bold;
    font-size: larger;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  form#job-offer-options-form {
    .form-check.custom-controls-stacked {
      margin-left: 3rem;
    }
    legend[data-action=form-group-toggle] {
      margin-left: .75rem;
      font-size: 1rem;
      background-color: #F5F5F5;
      padding: .75rem;
      border-radius: .25rem;

    }
  }
}
.job-label-search-form {
  font-weight: bold;
  text-align: center;
  display: block;
  font-size: larger;
}
.btn-detail-job-list {
  border-radius: 20px;
}
.custom-subcategories-checkbox {
  overflow: auto;
  height: 300px;
  padding: 2rem !important;
  margin: 1rem !important;
  border-radius: 20px;
  border: $card-border-width solid $card-border-color;
}

div.custom-category-checkbox {
  margin-left: -1.5rem !important;
  font-weight: bold;
}

@include media-breakpoint-down(md) {
  .filter-choice-card {
    height: 600px;
    position: fixed;
    z-index: 9;
    top: 90px;
    margin-left: -15px;
    width: 100%;
    border-radius: unset;
    display: none;
    overflow-y: scroll;
  }
  button.show-job-filter {
    position: fixed;
    z-index: 10;
    top: 100px;
    background-image: linear-gradient(90deg,#57BBC8,#37b4c4 4%,#2C5E64);
    color: white !important;
  }
  #myaladom-pole-emploi .form-check.custom-controls-stacked {
    display: none;
  }
  p.filter-title {
    font-weight: bold;
    font-size: smaller;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  form#job-offer-options-form {
    .form-check.custom-controls-stacked {
      margin-left: 3rem;
    }
    legend[data-action=form-group-toggle] {
      margin-left: 2rem;
      font-size: 0.8rem;
    }
  }
  .form-group {
    margin-bottom: 10px;
  }

  .job-offer-search-form {
    form.offer-search-form .form-group .select2-container {
      max-width: unset;
      min-width: 250px;
    }
  }

  .offer-list, #myaladom-user-card, #my-candidacy-list {
    .custom-list-item-card figure {
      width: 90px;
      height: 90px;
      top: 12%;
    }
    .option-card-badge {
      margin-top: 0;
    }
  }
}

div.arc {
  border-radius: 0 0 70% 70%;
  display: block;
  background: $light-grey;
  width: 100%;
  height: 70px;
  margin-top: -35px;

  &:after {
    content: "";
    display: block;
    width: inherit;
    height: 35px;
    background: white;
  }
  &.arc-with-blue-border {
    border-bottom: 1px solid $royal-blue;
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-3 {
    .list-item-card.white-card {
      height: 260px;
    }
  }

  .col-lg-4 {
    .list-item-card.white-card {
      height: 350px;
    }
  }
}

.card-columns {
  @include media-breakpoint-down(lg) {
    column-count: 2;

    .card {
      display: inline-block;
      width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    column-count: 1;
  }
}

.category-card {
  position: relative;
  border: 1px solid #979797;
  margin-bottom: 1.625rem;
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  &-on-offer {
    border:2px solid $white;
    border-radius: 20px;
    background-color: $white;
    height: max-content;
  }
  &.btn-azure {
    border-radius: 4px;
  }

  .card-header {
    height: 200px;
    background-size: cover;
    background-color: $pale-darker;
    border-radius: 0;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    border: none;
    position: relative;
    overflow: hidden;

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.service-quotation-bloc {
    border-radius: 20px;
    box-shadow: 0 2px 6px $black;
    color: black;
    background: #f8f9fa;
    .card-header {
      background-color: $black;
      border-radius: 20px 20px 0 0;
      color: $white;
      height: 150px;
      padding: 1rem 0;
    }
    p {
      font-size: 1rem;
      color: black;
    }
  }

  .list-group {
     @include media-breakpoint-up(md) {
       padding: 25px 36px;
     }
    &-subcategory-offer {
      .list-group-item {
        color: black;
      }
    }

    padding: 1rem;
  }

  img.card-header-bg {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: 0;
  }

  img.card-header-icon {
    @include media-breakpoint-only(sm) {
      width: 50px;
      margin-bottom: .5rem;
    }

    width: 77px;
    height: auto;
    margin-bottom: 1rem;
    z-index: 1;
  }

  h2, h3, .h3 {
    @include media-breakpoint-only(sm) {
      font-size: 1.6rem;
    }

    font-size: 2rem;
    color: $white;
    font-weight: 500;
    z-index: 1;
  }

  a:hover {
    text-decoration: none;
  }

  &.btn div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


// Borders
/////////

.bordered {
  border: 2px solid;
}

@each $color, $value in $theme-colors {
  .border-#{color} {
    border-color: $value;
  }
}

.hr {
  @include compat-display-flex();
  @include compat-align-items(center);
  margin: 1em 0;
  text-align: center;

  &::before, &::after {
    content: '';
    @include compat-flex(1);
    margin: 0 .25rem;
    border-bottom: 1px solid $black;
  }
}


// Lists
/////////
body:not(.myaladom) .list-group-item {
  color: $medium-grey;
  line-height: 1.94;
  letter-spacing: -0.5px;
  padding: 0;
  border: 0;
  background-color: transparent;

  &:hover {
    text-decoration: none;
    color: $darker-grey;
  }
}

.list-group-item-dark {
  color: #434342;
  background-color: #d4d4d4;
}

// Sections
/////////
.section-separator {
  margin-top: -40px;
  height: 160px;
  z-index: 0;
  position: relative;
  &.no-relative {
    position: unset !important;
  }

  img {
    width: 100vw;
    position: absolute;
    right: 0;
  }

  #page-footer & svg {
    position: absolute;
    right: 0;
  }
}
.signin-box {
  background-color: $testimony-blue-op20;
}

.connect {
  .where-separator {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;
  }
  .where-separator::before, .where-separator::after {
    content: "";
    display: block;
    height: 3px;
    background-color: $testimony-blue-op20;
  }
}

#signin_email:not(.collapsed) {
  #selector-signin_email {
    .btn-testimony {
      background-color: white!important;
      border: 1px solid $testimony-blue;
      color: $testimony-blue;
      box-shadow: inset 0 3px 6px rgba(0,0,0,.1607843137);
    }
  }
}

// Containers
/////////
header.container-fluid {
  padding:0;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}

.container-xl.larger {
  max-width: 1500px;
}

#pro-header.container-fluid {
  padding: inherit;
}

.centered-text-container {
  max-width: 600px;
  margin: auto;
}

// Buttons
//////////
.btn {
  &:focus {
    box-shadow: none;
  }
  &-danger {
    @include btn-color(#F12B43);
  }
  &-azure {
    @include btn-color($azure-blue);
    background-color: $azure-blue !important;
    a i {
      color: white;
    }
  }
  &-marine {
    @include btn-color($black);
    background-color: $black !important;
    font-weight: 500;
    a i {
      color: white;
    }
    &:hover {
      background-color: $white !important;
      border: 1px solid $testimony-blue;
      color: $testimony-blue !important;
    }
  }
  &-testimony {
    @include btn-color($testimony-blue);
    background-color: $testimony-blue !important;
    font-weight: 500;
    a i {
      color: white;
    }
    &:hover {
      background-color: $white !important;
      border: 1px solid $testimony-blue;
      color: $testimony-blue !important;
      box-shadow: $default-inside-shadow;
    }
  }
  &-black2 {
    background-color:#000000;
    font: normal normal 400 12px/18px Montserrat;
    color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 23px;
  }
  &-outline-testimony {
    @include btn-color(white);
    background-color: white;
    border: 1px solid $testimony-blue;
    color: $testimony-blue;
    font-weight: 500;
    a i {
      color: $testimony-blue;
    }
    &:hover {
      background-color: $testimony-blue !important;
      color: white;
    }
  }
  &-sapphire {
    @include btn-color($sapphire);
    background-color: $sapphire !important;
    a i {
      color: white;
    }
  }
  &-testimony-blue-op20 {
    color: $testimony-blue;
    background-color: $testimony-blue-op20 !important;
    a i {
      color: $testimony-blue;
    }
  }
  &-clear-blue {
    @include btn-color($light-blue);
  }
  &-grey {
    @include btn-color($medium-grey);
  }
  &-black {
    @include btn-color($black);
    &:hover {
      background-color: $off-white;
      color: $black;
    }
  }
  &-white {
    @include btn-color($off-white);
    color: $black;
    &:hover {
      background-color: rgba($black, .3);
    }
    &.transparent-hover {
      color: $testimony-blue;
      &.offer-color {
        color: rgb(87, 187, 200);
      }
      &:hover {
        background-color: transparent;
        border: 1px solid $white;
        color: white;
      }
    }
  }
  &-outline-white {
    @include btn-color(transparent);
    border: 1px solid $off-white;
    color: $off-white;
    &:hover {
      background-color: $off-white;
      color: $testimony-blue;
    }
  }

  &-azure-secondary {
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 600;
    color: $azure-blue;
    letter-spacing: 0;
    border: 1px solid $azure-blue;
    border-radius: 4px;

    @include media-breakpoint-down(lg) {
      font-size: .75rem;
    }

    &:hover {
      color:white;
      background-color: $azure-blue;
    }
  }

  &-gold {
    background-color: $yellow-gold;
    color: black;
    &:hover {
      border: 1px solid $testimony-blue;
      background-color: transparent;
      color: $testimony-blue;
    }
  }
  &-white-border {
    background: transparent;
    border: 1px solid white;
    color: white;
    font-weight: 500;
    &:hover {
      background: white;
      border: 1px solid white;
      color: $testimony-blue;
    }
  }
  &-green-payment {
    background-color: $checked-identity-color;
    color: black;
    font-weight: 500;
    &:hover {
      background: transparent;
      border: 1px solid $checked-identity-color;
      color: $checked-identity-color;
    }
  }

  &-rounded {
    border-radius: 1em;
  }

  &-max-rounded {
    border-radius: 500px;
  }
}
.btn-upper {
  text-transform: uppercase;
}
.btn-home-cv {
  text-transform: uppercase;
  font-weight: 600;
  background: $azure-blue;
  color: $white;

  @include media-breakpoint-down(sm) {
    background: $white;
    color: $azure-blue;
  }
}

.btn-facebook, .btn-twitter, .btn-google, .btn-bconnect {
  max-width: 300px;
  width: 100%;
  @include media-breakpoint-down(xs) {
    font-size: .75rem;
  }
}

a.btn-bconnect {
  background-color: #F83A34;
  margin-top: 1.5rem;
  &:hover {
    background: darken(#F83A34, 10%);
  }
}

// Breadcrumbs
////////
.breadcrumb {
  font-size: .8rem;
  a {
    color: $black;
  }
}
.organization-breadcrumb {
  a {
    text-decoration: auto;
  }
  span[itemprop=name] {
    color: #707070;
    letter-spacing: 0;
    opacity: 1;
    font: normal normal 300 14px/18px Montserrat;
    text-transform: lowercase;
  }
  .breadcrumb {
    padding: 1.5rem 0 0 0;
    .breadcrumb-item.active span[itemprop=name] {
      font: normal normal 600 14px/18px Montserrat !important;
      text-transform: lowercase;
      padding: 0 .1rem
    }
    .breadcrumb-item+.breadcrumb-item:before {
      content: ">";
    }
  }
  hr {
    margin-top: -.5rem;
  }
}

// Organizations
////////////////
.organization-bloc {
  @include media-breakpoint-down(md) {
    padding: .5rem;
  }
}
figure.organization-img, figure.organization-img-mobile {
  max-width: 200px;
  background: #FFFFFF;
  border: 2px solid $black;
  border-radius: 19px;
  z-index: 200;
  margin: -8rem auto 0;
  position: relative;
  text-align: center;
  @include media-breakpoint-down(md) {
    margin: -8rem auto;
  }
  @include media-breakpoint-down(sm) {
    margin: -4rem 1rem auto;
  }

  img {
    border-radius: 19px;
    min-height: 150px;
    @include media-breakpoint-down(md) {
      min-height: 50px;
    }
  }
}
figure.organization-img-mobile {
  margin: 1rem auto !important;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
#organization-list {
  h1 {
    color: $black;
    overflow: hidden;
    width: 100%;
  }
  .organization-intro {
    margin-top: 2rem;
    p {
      max-width: 750px;
      letter-spacing: 0;
      color: $black;
    }
  }
  .organization-list-item-card {
    flex: 1;
    align-content: stretch;
    height: 100%;
    position: relative;
    @include media-breakpoint-up(md) {
      &:hover {
        transform: scale(1.1);
        transform-origin: 50% 50%;
        transition: 1s ease-in-out;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: $black;
    }
    .organization-head-card {
      padding: 3rem;
      background: $black;
      border-radius: 19px 19px 0 0;
      position: relative;
      font-size: .95rem;
      overflow: hidden;
      height: 166px;
      @include media-breakpoint-down(md) {
        height: 120px;
      }
      @include media-breakpoint-down(sm) {
        height: 100px;
      }
    }
    .organization-body-card {
      flex: 1;
      z-index: 100;
      padding: 3rem;
      min-height: 500px;
      background: #F9F9FA;
      box-shadow: 0 3px 6px #00000029;
      border-radius: 0 0 19px 19px;
      @include media-breakpoint-down(md) {
        min-height: 400px;
      }
      @include media-breakpoint-down(sm) {
        max-height: 320px;
        min-height: unset;
        padding: 2rem;
        font-size: .9rem;
      }
      @include media-breakpoint-down(xs) {
        min-height: 250px;
        padding: 1rem;
        font-size: .8rem;
      }
      h2 {
        color: $black;
        text-align: center;
        margin-top: 2rem;
        @include media-breakpoint-down(md) {
          margin-top: 10rem;
        }
        @include media-breakpoint-down(sm) {
          margin-top: 1.5rem;
          overflow: hidden;
          text-overflow: clip;
        }
      }
      p {
        margin-top: 2rem;
        letter-spacing: 0;
        color: $black;
        //text-overflow: ellipsis;
        // Cut text on four lines
        @include media-breakpoint-down(md) {
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
#organization-detail {
  a.btn-testimony {
    color: $white;
  }
  div.organization-header {
    .h1-display-mobile-organization {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    h1 {
      .verbose-h1 {
        font: normal normal 300 20px/45px Montserrat;
        letter-spacing: 0;
        line-height: 0;
      }
      .name-h1 {
        font: normal normal 800 30px/45px Montserrat;
        letter-spacing: 0;
        line-height: 0;
      }
    }
  }

  h2 {
    font: normal normal 800 30px/37px Montserrat;
    letter-spacing: 0;
    color: #000000;
    &.h2-organization-description {
      margin-top: 5rem;
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }
    }
  }

  figure.organization-img {
    margin: -7rem 2rem 0;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &.organization-agency-detail {
    figure.organization-img {
      margin: -9rem 2rem 0;
    }
    div.organization-header {
      h1 {
        .name-h1 {
          font: normal normal 800 25px/45px Montserrat;
        }
      }
    }
    div.rm-margin-mobile {
      @include media-breakpoint-down(lg) {
        padding: unset;
      }
    }
    .btn-dark {
      background-color: $black;
      text-align: center;
      font: normal normal normal 16px/19px Montserrat;
      letter-spacing: 0;
      color: $white;
      border-radius: 25px;
      padding: .5rem 1.5rem;
    }
    .organization-map {
      border-radius: 15px 15px 0 0;
      border: 2px solid #EBEBEB;
    }
  }
  .hours-box {
    background: $black;
    box-shadow: 0 3px 6px #00000029;
    height: 100%;
    color : $white;
    padding: 10%;
    margin-right: -15px;
    border-radius: 15px;
    @include media-breakpoint-down(lg) {
      margin-right:0;
    }
    h2 {
      color: $white;
    }
    p {
      line-height: 2rem;
      font-size: 1.1rem;
      margin-top: 2rem;
    }
  }

  .subarea-collapse {
    background: $testimony-blue;
    border-radius: 19px;
    color: $white;
    padding: 1rem;
    width: 100%;
    border: unset;
    &.red-background {
      background: $red;
    }

    i {
      font-weight: bold;
    }

    h2 {
      padding: 0.8rem 0.5rem;
      color: $white;
      font: normal normal 600 20px/24px Montserrat;
      letter-spacing: 0;
      text-align: left;
      @include media-breakpoint-down(sm) {
        font: normal normal 600 16px/20px Montserrat;
      }
    }
  }

  .white-box {
    border: 2px solid #EBEBEB;
    border-radius: 19px;
    padding: 2rem 1rem;

    i.ala-location {
      font-size: 2rem;
      color: $red;
    }

    a.city-link {
      font-weight: bold;
      color: $red;
      text-decoration: auto;
      margin: 1rem;
      line-height: 1.5rem;
      @include media-breakpoint-down(md) {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin: 1rem 0;
        line-height: 1rem;
      }
    }
  }

  .agency-link {
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0;
    color: #707070;
    text-transform: uppercase;
    text-decoration: auto;
    display: block;
    line-height: 1.8rem;
  }

  .other-organization-box {
    @media (min-width: 1850px) {
      position: absolute;
      top: 140px;
      right: 10px;
      width: 19%;
    }
    @media (max-width: 1850px) {
      margin-top: 1.5rem;
      margin-left: 15px;
    }
  }
}

.red-color {
  color: $red;
}
.black-color {
  color: #000000 !important;
}
.blue-color {
  color: $testimony-blue !important;
}
.large-hr {
  border: 2px solid #000000;
}
.very-dark {
  background: #000000 !important;
}
.blue-badge {
  border-radius: 25px;
  background: $white;
  border: 1px solid $testimony-blue;
  color: $testimony-blue;
  &:hover {
    background: $testimony-blue;
    border: 1px solid $white;
    color: $white;
  }
}
.red-badge {
  border-radius: 25px;
  background: $white;
  border: 1px solid $red;
  color: $red;
  &:hover {
    background: $red;
    border: 1px solid $white;
    color: $white;
  }
}
.btn-organization-phone {
  background: $testimony-blue;
  border-radius: 25px;
  color: $white;
  font: normal normal bold 16px/19px Montserrat;
  letter-spacing: 0;
  padding: .8rem;
  i {
    color: $white;
  }
  &:hover {
    background: $white;
    color: $testimony-blue;
    i {
      color: $testimony-blue;
    }
  }
}

#organization-detail, #childcarecenter-detail {
  div.organization-header {
    background: $black;
    color: $white;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 20px;
    min-height: 160px;
    @include media-breakpoint-down(md) {
      min-height: 300px;
    }
    h1 {
      color: $white;
      margin-top: 1.5rem;
    }
  }
  #map {
    margin-left: -15px !important;
    @include media-breakpoint-down(lg) {
      margin: unset !important;
    }
    .organization-map {
      margin-top: 5rem;
      border: 3px solid $black;
      border-radius: 15px;
    }
  }
  .organization-address {
    border: 2px solid #EBEBEB;
    border-radius: 0 0 15px 15px;
    margin-left: -15px;
    margin-right: 15px;
    margin-top: -.5rem;
    i {
      color: $red;
      font-size: 2rem;
    }
    p {
      color: $red;
      font-size: 1rem;
      margin-bottom: 0;
      padding: 0 1rem;
    }
    @include media-breakpoint-down(lg) {
      margin: 0 0 2rem 0 !important;
      text-align: center;
    }
  }
}

////page Childcare-center
#childcarecenter-detail {
  h1 {
    font: normal normal 800 25px/45px Montserrat;
  }
}
.childcare {
  header {
    p {
      font-size: 1rem !important;
      font-style: unset !important;
    }
  }
  .accordion-locations {
    h2 {
      button {
        font-weight: 600;
      }
      :hover {
        transition-duration: .35s;
        opacity: .5;
        text-decoration: none;
      }
    }
    .collapsed {
      i {
        transition-duration: .35s;
        transform: rotate(90deg);
      }
    }
    .card-header {
      border-radius: 20px;
      border: 1px solid #e9ecef;
    }
  }
}

// Text
///////
.break-word {
  word-break: break-word;
  hyphens: auto;
  text-align: justify;
}

.line-height-initial {
  line-height: initial;
}
.line-height-normal {
  line-height: normal;
}

// Miscellaneous
////////////////

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.init-width {
  width: initial;
}

.align-elements {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-h200 {
  min-height: 200px;
}

.pr-6 {
  @include media-breakpoint-up(lg) {
    padding-right: 4.5rem;
  }
}

.mw-1300 {
  max-width: 1300px;
  margin: auto;
}

.mw-900 {
  max-width: 900px;
  margin: auto;
}

.mw-740 {
  max-width: 740px;
  margin: auto;
}

.mw-400 {
  max-width: 400px;
  margin: auto;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.pt-6 {
  padding-top: 5rem;
}

.ml-less-10 {
  margin-left: -10px !important;
}
.ml-less-20 {
  margin-left: -20px !important;
}

.b-100 {
  position: absolute;
  bottom: -100px;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 3rem !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;

  .leaflet-popup-content {
    text-align: center;
    width: initial !important;

    p {
      margin: 0.5rem;
    }
    .map-info-picture {
      width: 120px;
      margin: auto;
    }
  }
  a.btn.btn-testimony {
    border-radius: 50px;
  }
}

.f-bold {
  font-weight: bold;
}

.f-bold-600 {
  font-weight: 600;
}

// CV Catcher & Quote Request Modal
///////////////////////////////////
.cv-catcher, .quote-request {
  .filter-item:not(.cv-catcher-filter-item) {
    @include media-breakpoint-down(lg) {
      height: 300px !important;
    }
  }
  .job-detail.filter-item {
    height: fit-content;
    text-align: center;
  }

  .job-detail.filter-item:not(.cv-catcher-filter-item) {

    @include media-breakpoint-down(lg) {
      height: 550px !important;
    }

    @include media-breakpoint-down(md) {
      height: 480px !important;
    }
    &.px400 {
      height: 400px !important;
      @include media-breakpoint-down(md) {
        height: 500px !important;
      }
    }
  }
  .cv-catcher-headband, .quote-request-headband {
    background: $black;
    color: $off-white;
    padding: 0rem 1rem;
    margin-left: 15px;
  }
  .cv-catcher-text, .quote-request-text {
    font-weight: bold;
    font-size: 1.25rem;
    padding: 1rem .25rem;
    color: black;
  }
  .cv-catcher-headband-text, .quote-request-headband-text {
    font-size: 1rem;
    color: $off-white;
    padding: 10px;
  }
  .cv-catcher-headband-text-bloc, .quote-request-headband-text-bloc {
    border-radius: 0 0 20px 20px;
    width: 100%;
    margin: 0 15px;
    padding: 1rem;
  }
}
.btn.btn-grey {
  background-color: $medium-grey;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  letter-spacing: 0;
}
.btn-grey.color-white {
  color: $off-white;
}
.color-white {
  color: $off-white !important;
}

.quote-request .btn-grey {
  font-size: small;
  color: white !important;
}

.cv-catcher-home {
  margin-top: 2rem;
  font-size: 0.75rem;

  .btn-azure.color-white {
    padding: 5px;
    font-size: 1rem !important;
    color: $off-white !important;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      background-color: $off-white;
      color: $blue;
    }
  }
  p {
    font-size: 1rem !important;
  }
}
.btn-azure {
  color: $white !important;
  font-weight: 600 !important;
  letter-spacing: 0;
}

.btn.btn-azure-secondary {
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  color: #0088ff;
  letter-spacing: 0;
  border: 1px solid #0088ff;
  border-radius: 4px;
}

.grandient-bloc {
  background: $blue-gradient;
  color: $off-white;
  border-radius: 4px;
  padding: 4px;
  font-weight: bold;
  margin-bottom: 1rem;
}
#green-validate{
  padding-top: 2rem;
  .text-black{
    font-size: 1.5rem;
    padding-top:2rem;
    line-height: 25px;
  }
  .text_question{
    font-size: 1rem;
    padding-bottom:1rem;
    @media (min-width: 770px) and (max-width: 1260px){
        line-height:18px;
        padding-top: 0.5rem;
    }
  }
}
// Quotation Hub
////////////////
div.quotation-hub {
  text-align: center;

  h1 {
    color: $black;
    font-size: 30px;
    font-weight: 800;
  }
  p {
    color: $black;
    font-size: 25px;
  }
  &.banner {
    background-color: unset;
    padding-bottom: 0;
  }
  .category-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 6px #0000004D, inset 0 0 0 #00000000;
    width: 350px;
    height: 170px;
    transition: box-shadow 600ms ease-in-out;
    margin: 0 10px 20px;
    padding: 0 15px;
    @include media-breakpoint-down(sm) {
      height: 150px;
    }
    h2 {
      color: $testimony-blue;
      font-size: large;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 1.2;
      margin: 0;
    }
    &:hover {
      @include pressed-btn();
    }
    .card-header {
      background: none;
      height: 100%;
      @include media-breakpoint-down(xs) {
          padding: 1.5rem 0.5rem 0.5rem;
      }
    }
    i {
      color: $testimony-blue;
      font-size: 60px;
      padding-bottom: 20px;
    }
  }
}

.level1-subcategory {
  .btn.btn-rounded {
    text-transform: inherit;
  }
  .btn.btn-azure {
     color: $white !important;
  }
  .custom-select {
    height: 40px !important;
  }
  button.btn.btn-azure[type=submit] {
    height: 40px;
  }
  .plr-0-or-15 {
    padding-left: 0;
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 0;
    }
  }
  .box-gray {
    background: $off-white;
  }
  .news-item-picture {
    max-width: 100%;
  }
}
.last-news a, h2 {
  color: $black;
}
.last-news {
  border-radius: 30px;
}

.btn-rounded {
  border-radius: 1em;
}
.btn-more-rounded {
  border-radius: 50px;
  text-transform: lowercase !important;
  font-weight: 300 !important;
}

.background-unset {
  background-color: unset;
}

.job-color {
  background: #58bbc8;
  color: $white;
  &:hover {
    background: #387880;
  }
}
.subcategory-listing-banner {
  background: $electric-blue;
  color: $white;
  padding: 1.5rem;
  border-radius: 20px;

  @include media-breakpoint-up(md) {
    margin-left: 5rem;
  }

  h5 {
    color: $white;
  }
}

@include media-breakpoint-up(md) {
  .margin-8-desktop {
    margin-top: 8rem;
  }
}
.job-bloc {
  background: #58bbc8;
  color: $white;
  padding: 0 1rem !important;
  border-radius: 5%;

  @include media-breakpoint-up(md) {
    margin-left: 5rem;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  h5 {
    color: $white;
  }
  h2, h3 {
    color: $white;
    margin-top: 2rem;
    font-size: 1.18rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1rem;
  }

}
.subcategory-search-form {
  div.form-group.full-form-group {
    label {
      font-weight: 600;
    }
  }
}

.offer-city-search-form.d-block {
  span.select2.select2-container {
    width: unset !important;
    min-width: unset !important;
    @include media-breakpoint-down(xs) {
      margin-left: -1rem;
      max-width: 170px;
      width: 170px;
    }
  }
  span.select2-selection.select2-selection--single.custom-select {
    height: 42px;
  }
}

.container.custom-container {
  @include media-breakpoint-up(xs) {
    max-width: 540px;
  }
  @include media-breakpoint-up(sm) {
    max-width: 720px;
  }
  @include media-breakpoint-up(md) {
    max-width: 960px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 1600px;
  }
}

.no-ml {
  margin-left: 0 !important;
}
.mt-7 {
  margin-top: 5rem !important;
}
.mt-12 {
  margin-top: 12rem !important;
}
.my-7 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}
.ml-5-up-lg {
  @include media-breakpoint-up(lg) {
    margin-left: 3rem
  }
}
.title-access-category {
  color: $gray-800;
}
.link-danger {
  color: #ff5a5f;
  font-size: large;
}
.text-decoration-revert {
  text-decoration: revert;
}

// Stepper collapse
///////////////////

.stepper {
  .card-header.active {
    background-color: $testimony-blue;

    button {
      color: white;
    }

  }
  .card-header {
    button {
      color: $testimony-blue;
    }
  }
}

// Offers search form & Header Form
///////////////////////////////////

.offer-search {
  &.with-white-background {
    background-color: $white;  // TODO : verifier avec le market si on modifie tous les forms
    border: 1px solid $medium-grey;
  }
  border-radius: 4px;
  margin: auto;
  margin-top: 2rem;

  @include media-breakpoint-down(sm) {
    max-width: 450px;
    position: relative;
  }

  @include media-breakpoint-down(xs) {
    //margin-left: .5rem;
    //margin-right: .5rem;
  }

  @include media-breakpoint-up(md) {
    min-width: 400px;
  }

  &-form {
    padding: 1.4rem;
  }

  &-form-box {
    background-image: linear-gradient(to bottom, #2255ff, #0087ff);
    border: solid 0.5px #2255ff;
    border-radius: 4px;
    height: 100%;

    span {
      font-weight: 600;
      font-size: 1.125rem;
      color: $white;
      letter-spacing: -1px;
      line-height: normal;
      @include media-breakpoint-only(md) {
        font-size: 1rem;
      }
    }

    img {
      width: 125px;
      max-width: 100%;
      height: auto;
      float: right;
      margin-top: .5rem;
    }
  }

  &-form-row {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .offer-search-form-type {
      border: 1px solid $medium-grey;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      font-weight: 600;
      letter-spacing: -1px;
      text-align: center;
      font-size: 1.125rem;
      position: relative;
      line-height: 1.5rem;
      padding: .2rem;
      cursor: pointer;

      figure {
        position: relative;
        height: 40px;
      }

      img {
        position: absolute;
        height: 73px;
        width: auto;
        top: -20px;
        left: calc(50% - 36.5px);
        @include grayscale(100%);
      }

      &:hover img {
        @include grayscale(50%);
      }

      &.active {
        border-color: $clear-blue;
        box-shadow: -1px 1px 5px 0px $medium-grey;

        span {
          color: $darker-grey;
        }

        img {
          filter: unset;
          -webkit-filter: unset;
        }
      }
    }
  }
}

form.offer-search-form {
  margin-top: 1.5rem;

  .main-offer-list & {
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .offer-search-form-row {
    margin-top: 1.5rem;
  }

  .form-group {
    max-width: 270px;
    margin-right: 1rem;

    .not-home & {
      margin-right: .7rem;
      @include media-breakpoint-down(lg) {
        max-width: 150px;
      }
    }

    .select2-container {
      min-width: 200px;
      max-width: 200px;
      @include media-breakpoint-down(lg) {
        min-width: 150px;
        max-width: 150px;
      }
    }
    span.select2-selection__placeholder {
      color: $custom-select-color;
      padding-left: 18px;
    }

    #select2-id_search_subcategory-container .select2-selection__placeholder:before, #select2-id_header_search_subcategory-container .select2-selection__placeholder:before {
      @include select2-placeholder();
      width: 15px;
      background: url('/ui/static/img/home.svg') center / contain no-repeat;
    }

    #select2-id_search_city-container .select2-selection__placeholder:before, #select2-id_header_search_city-container .select2-selection__placeholder:before {
      @include select2-placeholder();
      width: 18px;
      background: url('/ui/static/img/pin.svg') center / contain no-repeat;
    }
    [id^=select2-search_type] .select2-selection__placeholder:before {
      @include select2-placeholder();
      width: 15px;
      background: url('/ui/static/img/zoom.svg') center / contain no-repeat;
    }
  }

  .navbar-light & {
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: -1px;
    padding-left: .5rem;
    padding-right: .5rem;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
  }
}

.hero form.offer-search-form {
  .form-group, .select2-container {
    max-width: 260px;
    min-width: 260px;
    width: 100%;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      max-width: unset;
      min-width: unset;
    }
  }
  .form-group:nth-of-type(4) {
    min-width: initial;
  }
  div.form-group {
    @include media-breakpoint-down(sm) {
      margin: 1rem auto 0;
    }

    span.select2-selection.select2-selection--single, span.select2-selection.select2-selection--multiple {
      height: 48px;
      border-color: #9b9b99;
      span.select2-selection__rendered {
        line-height: 38px;
        font-weight: 500;
        font-size: 1.125rem;
        letter-spacing: -1px;
      }
      span.select2-selection__placeholder {
        font-size: 1.125rem;
        letter-spacing: -1px;
        font-weight: 600;
        padding-left: 25px;
      }
      span.select2-selection__arrow {
        top: 7px;
      }
    }
    button[type=submit] {
      @include media-breakpoint-up(md) {
        height: 48px;
        width: 48px;
      }
    }
  }
}

form.offer-search-form .offer-search-form-row .form-group .select2-container {
  text-align: center;
}

.main-offer-list .offer-search {
  margin: auto;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    max-width: 896px;
  }

  @include media-breakpoint-only(md) {
    max-width: 768px;
  }
}

.form-inline.offer-search-form, .form-inline .offer-search-form, .offer-search-form .form-inline {
  .form-group {
    margin-top: 0;
  }
}
.offer-search-form {
  .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
    border-radius: 10px;
    b[role=presentation], span.select2-selection__arrow {
      display: none;
    }
    span.select2-selection__rendered {
      //padding-top: 0;
    }
  }
}
.btn-job-search-form {
  border-radius: 10px;
}
.btn-actions-account {
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  .btn-outline-danger {
    @include media-breakpoint-up(sm) {
      float: right;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

// Slider
///////////
.ala-slider {
  width: 100%;
  display: flex;
  position: relative;
  overflow-x: hidden;

  &-content {
    width: 100%;
    display: flex;
    //align-items: center; // FIXME : News card not with same height with this property
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .ala-slide {
      display: flex;
      flex-shrink: 0;
    }
  }


  &-arrows {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 100%;
    font-size: 0.875rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    .ala-slider-prev {
      display: none;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0,-50%);
      border-radius: 0 20px 20px 0;
    }

    .ala-slider-next {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-100%,-50%);
      border-radius: 20px 0 0 20px;
    }

    i {
      font-size: 1.5rem;
      background-color: #2653fb;
      color: white;
      height: fit-content;
      cursor: pointer;
      padding: .5rem;
      z-index: 100;
      transition: background-color .5s, color .5s;

      &:hover {
        background-color: rgba(28,29,33, .75);
        color: #EEEFF7;
        z-index: 100;
      }
    }

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  &:not(.modal-body &) {
    figure {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}


.clip-path-unset {
  clip-path: unset !important;
  -webkit-clip-path: unset !important;
}

.display-flex {
  display: flex;
}
.hidden-template {
  display: none !important;
}

.display-on- {
  &mobile {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }

  &desktop {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
}

.mobile-hide {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.mobile-show {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.all-screen-width {
  width: calc(100vw - var(--scrollbar-width)) !important;
  position: relative;
  left: calc(50% - var(--scrollbar-width)/2);
  transform: translateX(-50%);
}

@include media-breakpoint-down(sm) {
  .all-screen-width-on-mobile {
    width: calc(100vw - var(--scrollbar-width)) !important;
    position: relative;
    left: calc(50%);
    transform: translateX(-50%);
  }
}

.spinner-aladom {
  display: inline-block;
  background: $white url("/ui/static/img/ala-loading.gif") center / 80% no-repeat;
  border: 1px solid $black;
  border-radius: 50%;
  padding: 10px;
  height: 80px;
  width: 80px;
}

.aladom-loading {
  //TODO not use
  background: url("/ui/static/img/ala-loading.gif") center / contain no-repeat;
  height: 100px;
  width: 100px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 70%;
}

body.pro-offers {
  #alert-messages:not(.home-messages) {
    margin-top: unset;
  }
}

.myaladom #alert-messages {
  margin-top: 30px;
}

i.load-gif {
  background: url("/ui/static/img/load.gif") right / contain no-repeat;
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
  z-index: 100;
}
#new-job-search-form {
  i.load-gif {
    @include media-breakpoint-down(md) {
      top: -5px !important;
      right: 10px;
    }
  }
}
#home-search-form {
  i.load-gif {
    position: absolute;
    right: 20px !important;
    top: 5px !important;
    font-size: 1.5rem !important;
    @include media-breakpoint-down(md) {
      top: -5px !important;
      right: 10px !important;
    }
  }
}
form#navbar-search-form {
  @include media-breakpoint-up(lg) {
    i.load-gif {
      top: -6px !important;
      right: 10px;
    }
  }
}

// Search Form
//////////////////
.aladom-autocomplete {
  position: relative;
  i.aladom-autocomplete-prefix-icon, i.aladom-autocomplete-suffix-icon {
    color: $black;
  }
  i.aladom-autocomplete-prefix-icon {
    position: absolute;
    font-size: 1.5rem;
    top: calc(50% - 12px);
  }
  i.aladom-autocomplete-prefix-icon {
    left: 5px;
  }

  i.aladom-autocomplete-suffix-icon {
    right: 5px;
  }
  i.ala-location {
    color: $red;
  }
  input[type=text].aladom-autocomplete-search-input {
    max-width: 100%;
    padding: 0.5rem 2.2rem 0.5rem 2.2rem;
    color: $black;

    &, &::placeholder {
      font-weight: 600;
    }

    &.rounded {
      border-radius: 500px !important;
    }

    &.bordered {
      border: 2px solid !important;
      outline: transparent;
    }

    &.shadowed {
      width: 100%;
      padding: 20px 38px;
      box-shadow: $default-shadow;
      border: none;
      outline: transparent;
      @include media-breakpoint-down(md) {
        padding: 10px 50px;
      }
    }
  }

  .aladom-autocomplete-result {
    box-shadow: $default-shadow;
    z-index: 1001;
    border-radius: 20px;
    background-color: white;
    padding: 2rem .5rem 2rem 2rem;
    margin: 5px 0;
    display: none;
    position: absolute;
    width: 100%;
    @include media-breakpoint-down(md) {
      left: 0;
      padding: 15px;
    }

    .result-label {
      line-height: .9rem;
    }

    .result-select {
      color: $black;
      text-decoration: none;
      font-weight: 500;
      display: block;
      &.selected-autocomplete {
        border: 1px solid gray;
      }
      &:hover {
        color: $testimony-blue;
        text-decoration: none;
      }

      &.noicon {
        padding-left: 1.7rem;
      }

      i {
        color: $black !important;
        position: initial !important;
        padding-right: .5rem !important;
      }
    }
  }

  legend {
    width: auto;
    margin-bottom: 0;
    font-weight: 600;
    font-size: .95rem;
    top: calc(50% - 12.5px);
    color: $black;
    left: 12px;
  }
}

.aladom-multichoices-api-autocomplete-list {
  display: flex;
  flex-wrap: wrap;

  .aladom-multichoices-api-autocomplete-list-item {
    position: relative;
    margin: .5rem;
    padding: .5rem 2rem .5rem 1rem;
    border-radius: 500px;
    font-size: .75rem;

    i {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      margin-left: .5rem;
      font-size: .75rem;
      font-weight: 900;
      cursor: pointer;

      &:hover {
        transform: translateY(-50%) scale(1.2);
      }
    }
  }

  .city-item {
    color: $white;
    background-color: $light-red;
  }

  .subcategory-item, .subcategory-job-item {
    color: $white;
    background-color: $testimony-blue;
  }
}

.aladom-autocomplete-category-container, .category_step, .city_step, .aladom-search-form {

  .category-input-result, .category-search, .city-search, .category-result, .city-input-result {
    box-shadow: $default-shadow;
    border-radius: 20px;
    background-color: white;
    padding: 2rem .5rem 2rem 2rem;

    &-title {
      color: black;
      font-weight: 600;
      font-size: 1rem;
      padding-bottom: .5rem;
      @include media-breakpoint-down(md) {
        font-size: .7rem;
        padding-bottom: 0;
        padding-left: .7rem;
      }
    }

    .category-label {
      padding-top: 1rem;
      display: flex;
      @include media-breakpoint-down(md) {
        padding-bottom: .5rem;
      }
    }

    .category-label button:not(.result-select) {
      font-weight: 500;
      line-height: .9rem;
      color: $black;
      display: flex;
      align-items: center;
      padding-left: .5rem;
      margin-bottom: 0;
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        font-size: .75rem;
        line-height: .8rem;
        padding-left: .4rem;
      }
      @include media-breakpoint-down(md) {
        font-size: .7rem;
      }

      i {
        margin-right: .5rem;
        font-weight: 500;
        font-size: 1.15rem;
        width: 40px;
        @include media-breakpoint-down(lg) {
          width: 30px;
        }
      }
    }
  }
}

.category-search {
  button.category-select {
    text-transform: uppercase;
    font-weight: 600;
    line-height: .8rem;
    color: $testimony-blue;
    padding: .3rem 0 .3rem 40px;
    font-size: .75rem;
    list-style: none;
    display: block;

    a {
      color: $testimony-blue;
      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }
}

.city_step {
  .city-search {
    .btn-testimony {
      color: white;
      justify-content: center;
      padding: .6rem 35px .6rem .5rem;
      box-shadow: $default-shadow;
      border-radius: 25px;
      font-size: .8rem;
      max-width: 220px;
      line-height: .9rem;
      display: flex;
      align-items: center;
      font-weight: 500;

      &:hover {
        color: $testimony-blue;

        i {
          background-color: white;
          color: $testimony-blue;
          border-color: $testimony-blue;
          border-width: 1px;
          padding-top: .8rem;
        }
      }

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(15,140,198,.4);
      }

      i {
        transition: all .15s ease-in-out;
        position: absolute;
        right: -10px;
        background-color: $testimony-blue;
        border-radius: 50%;
        margin: 0;
        width: 45px;
        height: 45px;
        border: 2px solid white;
        padding-top: .75rem;
        box-shadow: $default-shadow;
        font-size: 1rem;
      }
    }
  }
}

.aladom-autocomplete-category, .aladom-search-form {

  &:not(.subcategory-multi-choice) .category-search {
    @include media-breakpoint-down(xs) {
      border-radius: 45px 45px 0 0;
      box-shadow: 0px -4px 11px 2px #0000002D;
      margin-bottom: 0;
      padding: 20px;
    }
  }

  .category-result {
    color: $testimony-blue;
    font-weight: 600;
    text-align: center;
    padding: 1rem 2.8rem 1rem 2rem;
    font-size: 1rem;
    position: relative;

    i.ala-refresh {
      position: absolute;
      right: 20px;
      font-size: 1.3rem;
      color: $black;
      top: calc(50% - 9px);
    }
  }
}


//#home-search-form, #page-header .navbar-search, .subcategory-multi-choice, .city-multi-choice, #new-job-search-form,
//#job-offer-alert-form {
//
//
//
//  .city-search {
//    position: relative;
//    padding: 1rem 1rem 3rem;
//
//    legend {
//      width: auto;
//      text-transform: uppercase;
//      margin-bottom: 0;
//      font-weight: 600;
//      font-size: .95rem;
//      top: calc(50% - 12.5px);
//      color: $black;
//      left: 12px;
//    }
//    .city-search-input {
//      padding: .5rem .5rem .5rem 2.2rem;
//      border-radius: 15px;
//      max-width: 220px;
//      margin: auto;
//    }
//    input[type=text]::placeholder {
//      text-align: center;

//      font-weight: 600;
//      opacity: 1;
//      text-transform: uppercase;
//      font-size: 1rem;
//    }
//
//    i.ala-location {
//      color: #f87072;
//      position: absolute;
//      left: 5px;
//      top: calc(50% - 12px);
//      font-size: 1.5rem;
//    }
//    i.ala-sort {
//      position: absolute;
//      right: 15px;
//      top: calc(50% - 12px);
//      font-size: 1.2rem;
//      color: $black;
//    }
//  }
//}

// ParticularTypeToggleSwitch
///////////////////////
.cyan {
  color: $cyan;
}

.electric-blue {
  color: $electric-blue;
}

.toggle {
    --height: 34px;

    position: relative;
    display: block;
    width: 170px;
    height: var(--height);
    border-radius: var(--height);
    cursor: pointer;
}

/* hiding default HTML checkbox */
.toggle input {
    display: none;
}

/* Creating slider */
.toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: $cyan;
    transition: all 0.6s ease-in-out;
}
.toggle .slider::before {
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: all 0.6s ease-in-out;
}

/* Adding slider effect */
.toggle input:checked + .slider {
    background-color: $electric-blue;
}
.toggle input:checked + .slider::before {
    transform: translateX(136px);
}

/* Adding text in the toggle switch */
.toggle .labels {
    position: absolute;
    top: 7px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
    content: attr(data-off);
    position: absolute;
    right: 6px;
    color: white;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
    content: attr(data-on);
    position: absolute;
    left: 50px;
    color: white;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.toggle input:checked~.labels::after {
    opacity: 0;
}

.toggle input:checked~.labels::before {
    opacity: 1;
}

.no-shrink {
  flex: 0 0 auto !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.alert-top-container {
  margin-top: 80px;
}

.custom-select {
  background: #FFF url("data:image/svg+xml;utf8,<svg id='Outline' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><title>163 arrow small down</title><path d='M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,0,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586A1,1,0,0,0,18.707,8.207Z'/></svg>") right .5rem center/15px 20px no-repeat;
  position: relative;
}

.dropdown-toggle {
  position: relative;
  padding-right: 2.5rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.leaflet-top, .leaflet-bottom {
  z-index: 900;
}

// overlay
//////////

.overlay-wrapper {

  height: 100%;

  &:not(.show).overlay-body {
    display: none;
  }

  &.show {
    position: relative;

    .overlay-body {
      position: absolute;
      inset: 0;
      z-index: 10;

      & > div {
        position: absolute;
        inset: 0;
        background-color: $gray-100;
        opacity: 0.85;
      }
    }
  }
}

// burger icon
//////////////
.burger-icon-horizontal, .burger-icon-vertical {
  transition: transform $transition-short-time ease-in-out;
  span {
    position: relative;
    background: $black;
    border-radius: 3px;
    z-index: 1;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }

  span {
    display: flex;
    width: 24px;
    height: 2px;
    margin-bottom: 5px;
    transform-origin: 5px 0;

    &:first-of-type {
      margin-top: 5px;
    }
  }
}

.burger-icon-vertical {
  transform: rotate(-90deg);
}

.font-weight-normal {
  font-weight: 500 !important;
}

// Word Cloud
.pro-news-box, .pro-word-cloud-box, .recruiter-box {
  border: 4px solid #EBEBEB;
  border-radius: 20px;
  padding: 2rem;
  height: 100%;
  h2 {
    font: normal normal 800 25px/25px Montserrat;
    letter-spacing: 0;
    color: $black;
    margin-bottom: 2rem;
    text-align: center;
  }
  p {
    text-align: left;
    font: normal italic 300 16px/25px Montserrat;
    letter-spacing: 0;
    color: $black;
    align-items: center;
  }
}
.pro-word-cloud-box {
  text-align: center;
  max-width: 600px;
  margin: 2rem auto;
  div.pro-word {
    text-align: center;
    font-family: Daydream;
    font-size: 3rem;
    color: $black;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }
}
.recruiter-container {
  margin: 1.5rem;
  @include media-breakpoint-down(md) {
    margin: 1.5rem 0;
  }
}
.recruiter-box {
  h2 {
    text-align: left;
    font-family: Daydream;
    font-size: 2rem;
  }
}

.subcategory-multi-choice .home-text-search {
  input[type=text] {
    width: 100%;
    padding: 15px 20px;
    transition: all .3s;
  }

  i{
    @include search-icon();
    cursor: text;
  }
}

.bootstrap-datetimepicker-widget table td.old, .bootstrap-datetimepicker-widget table td.new {
  color: #ccc !important;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover, .bootstrap-datetimepicker-widget table td span.active {
  background-color: $testimony-blue !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  border: none !important;
  box-shadow: 0 6px 10px #0000004D;
  border-radius: 10px;
}
#other-map {
  position: relative;
}

// unset ala-icons font family for all class begins by ala- not in i tag
[class^="ala-"]:not(i), [class*=" ala-"]:not(i) {
    font-family: unset !important;
}
.mt-5-undo-lg {
  @include media-breakpoint-down(lg) {
    margin-top: 2rem;
  }
}

.verification-card {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 9px;
  height: 300px;
  #id_picture_preview {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    height: unset;
  }
  .btn-verification {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .text-testimony {
    margin-top: -.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .verification-img {
    border-radius: 9px;
    background: $testimony-blue;
    height: 100%;
    text-align: center;
    display: flex;
    &.red-verification {
      background: #ff4736;
    }
    @include media-breakpoint-down(md) {
      padding: 1.5rem;
      cursor: pointer;
      display: block;
    }
    div.img-container {
      border-radius: 50%;
      background: $white;
      overflow: hidden;
      margin: auto 1rem;
    }
    img {
      width: 100%;
    }
    i {
      font-size: 5rem;
      margin: auto;
      color: $white;
    }
  }
  .verification-text, .after-verification {
    padding: 4rem 2rem 1rem;
    &.verification-code {
      padding: 0 1rem;
      @include media-breakpoint-down(lg) {
        padding: 0 2rem;
      }
    }
    h2 {
      color: $black;
    }
    p {
      color: $black;
      width: 80%;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .after-verification {
    @include media-breakpoint-down(lg) {
      text-align: center;
      padding: 2rem !important;
    }
  }
  .btn-container {
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
  .btn-verification {
    padding: .8rem;
    text-transform: unset;
    font-size: 1.1rem;
    margin-top: 2rem;
  }
}
.success-action {
  background: $green !important;
}
.btn-ignore {
  background: #D5D5D5C6;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 9px;
  color: $black;
  padding: .8rem 3rem;
  font-size: 1.1rem;
  margin: 2rem;
  text-transform: unset;
}
.code-input {
  input[type=number] {
    width: 40px !important;
    height: 60px !important;
    border: 3px solid #B4AFAF;
    border-radius: 9px;
    font-size: 2rem;
    padding: 0;
    box-shadow: none;
  }
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
  }
  .form-control:focus, .number-set {
    border: 2px solid $green !important;
  }
  .error-number-set {
    border: 2px solid $red !important;
  }
}
div.info-code {
  padding-top: .5rem;
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
}
a.change-phone-or-email {
  text-decoration: underline;
  color: $testimony-blue;
  cursor: pointer;
}
.offer-check, .contact-check {
  p.text-info {
    letter-spacing: 0;
    color: $black !important;
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  span.rounded-info {
    border-radius: 50%;
    border: 1px solid $black;
  }
  div.top-trust-icon {
    i {
      font-size: 6rem;
      color: $black;
    }
  }
  button.open-info-modal {
    border: none;
    background: unset;
    padding: 0;
  }
  .modal {
    .trust-icon {
      i {
        font-size: 5rem;
        color: $green;
      }
    }
    .modal-body {
      padding: 3rem;
      text-align: justify;
    }
  }
  button.re-send-code {
    border: none;
    background: unset;
    padding: 0;
    text-decoration: underline;
    color: $testimony-blue;
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
  }
}
div.error-code {
  color: $red;
  &.has-error-text {
    margin: -0.7rem 0;
  }
}
.text-decoration-underline {
  text-decoration: underline;
}

a[disabled] {
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

// alert job offer
//////////////////

.m-alert-container {
  margin-top: 10rem;
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}

.alert-card {
  border-radius: 50px;
  padding: 2rem;
  @include media-breakpoint-up(sm) {
    padding: 1rem 5rem;
  }
  .m-alert-icon {
    margin: 1.5rem 1rem 0 0;
    @include media-breakpoint-down(md) {
      text-align: center !important;
      margin: 0 0 1rem;
    }
  }
  h1 {
    color: $white;
    font-size: 1.8rem;
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      text-align: center;
      font-size: 1.4rem;
    }
  }
  i {
    font-size: 6rem;
    @include media-breakpoint-down(sm) {
      font-size: 4rem;
    }
  }
  a {
    color: $white;
    text-decoration: underline;
    font-weight: bolder;
  }
  .btn {
    font-weight: 500;
    color: $electric-blue;
    background-color: $white;
    border-radius: 20px;
    &:hover {
      color: $white;
      background-color: transparent;
      border: 1px solid $white;
    }
  }
  .mobile-align {
    @include media-breakpoint-down(sm) {
      text-align: center !important;
    }
  }
}
.ignore-alert-link {
  display: inline-block;
  color: $black;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 3rem;
  &:hover {
    text-decoration: none;
    color: $gray-800;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

// made to center an absolute element like here : https://www.aladom.fr/devis/menage/
.absolute-h-top-center {
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50% !important;
}

.absolute-h-bottom-center {
  position: absolute;
  transform: translate(-50%,50%);
  bottom: 0 !important;
  left: 50% !important;
}

.absolute-v-left-center {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50% !important;
  left: 0 !important;
}

.absolute-v-right-center {
  position: absolute;
  transform: translate(50%,-50%);
  top: 50% !important;
  right: 0 !important;
}

// ala-icons utils
//////////////////
.ala-2x {
  font-size: 2rem;
}
.ala-3x {
  font-size: 3rem;
}
.ala-4x {
  font-size: 4rem;
}

.ala-fw {
  width: 1.3em;
  text-align: center;
}

.ala-spin {
  -webkit-animation: ala-spin 2s infinite linear;
  animation: ala-spin 2s infinite linear;
  display: inline-block;
}

.ala-pulse {
  -webkit-animation: ala-spin 1s infinite steps(8);
  animation: ala-spin 1s infinite steps(8);
  display: inline-block;
}
.ala-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.btn-outline-electric {
  &:not(:disabled).active {
    color: #fff;
    background-color: $electric-blue;
    border-color: black;
  }
  border-color: black;
  background-color: black;
  color: white;
}

.circle {
  width: 18px;
  height: 18px;
  border: 1px solid $medium-grey;
  border-radius: 50%;
}

ul.no-style {
  padding-left: 0;
  display: inline-block;
  li {
    list-style-type: none;
    display: inline-block;
  }
}

// B-Connect landing page
/////////////////////////
#container-b-connect {
  background-color: gray;
  padding: 3rem;
  margin-bottom: -1rem;
  @include media-breakpoint-down(lg){
    padding: 5rem;
  }
  @include media-breakpoint-down(sm){
    padding: 2rem;
  }
  @include media-breakpoint-down(xs){
    padding: 1rem;
  }
  .white-card {
    border-radius: 25px;
    border: none;
    .row{
      margin: 0;
    }
    .col-lg-7 {
      padding: 6rem;
      @include media-breakpoint-down(lg){
        padding: 4rem;
      }
      @include media-breakpoint-down(sm){
        padding: 3rem;
      }
      h1{
        font-size: 2.5rem;
        color: #2653fb;
        margin-bottom: 3rem;
        @include media-breakpoint-down(xs){
          font-size: 2rem;
        }
      }
      p {
        color: $black;
      }
    }
    .col-lg-5 {
      background-color: #2653fb;
      border-radius: 0 25px 0 0;
      min-height: 400px;
      @include media-breakpoint-down(lg){
        border-radius: 0;
      }

      .row {
        justify-content: center;
        align-items: center;
      }
    }
  }
  .btn-landing-b-connect {
    padding: 1rem 3rem;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 92px;
    text-align: center;
    font: normal normal medium 20px/24px Montserrat;
    color: $white;
    background: $white;
    //margin: 2rem 1rem 2rem 0;
    margin-bottom: 1rem;
    text-decoration: none;
    display: inline-flex;

    &.light-blue-btn {
      background: $testimony-blue;
      border:2px solid $testimony-blue;
    }

    &.btn-facebook-bc {
      background: #3B5998;
      border:2px solid #3B5998;
      &:hover {
        text-decoration: none;
        border:2px solid #3B5998;
        background: transparent;
        color: #3B5998;
      }
    }

    &.btn-google-bc {
      background: $medium-grey;
      border:2px solid $medium-grey;
      &:hover {
        text-decoration: none;
        border:2px solid $medium-grey;
        background: transparent;
        color: $medium-grey;
      }
    }

    &.gradient-bconnect {
      background: linear-gradient(90deg, rgba(255,51,39,1) 0%, rgba(255,88,3,1) 100%);
      border:2px solid $white;
      &:hover {
        text-decoration: none;
        border:2px solid #FF3327;
        background: transparent;
        color: #FF3327;
      }
    }

    &:hover {
      text-decoration: none;
      border:2px solid $testimony-blue;
      background-color: transparent;
      color: $testimony-blue;
    }

    i {
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i {
        margin-right: 0;
      }
    }
  }
  .large-btn {
    height: 78px;
    padding: 1.5rem 3rem;
  }

  .lead-category {
    margin-top: 3rem;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 3rem;
      width: 100%;
    }
    div.white-icons {
      width: 66px;
      height: 100px;
      color: $white;
      margin: auto;
      text-align: center;
      vertical-align: middle;
      padding-top: 1rem;
      i {
        font-size: 2rem;
      }
    }
    div.col-9 {
      margin: auto;
      p {
        color: $electric-blue;
        margin: auto;
        font-size: 1rem;
        letter-spacing: 0;
        text-transform: lowercase;
        margin-left: 1rem;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  .reassurance-banner {
    border-radius: 0 0 25px 25px;
    padding: 1rem;
  }
}

.reassurance-banner {
  display: flex;
  flex-wrap: wrap;
  color: $white;
  background-color: $black;
  justify-content: center;
  .value {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    span {
      display: flex;
      margin: 1rem 0;
      padding: 0.5rem 1rem;
      font-size: 0.7rem;
    }
    i {
      font-size: 2rem;
      color: $electric-blue;
      margin-right: 1rem;
    }
  }
  .text-b {
    margin-left: 15px;
  }
  .btn-b {
    margin-left: 20%;
  }
  @include media-breakpoint-down(sm) {
    .row {
      justify-content: center;
      .text-b {
        text-align: center;
        margin-left: 0;
      }
      .btn-b {
        margin: 15px 0 0 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .margin-8-md {
    margin: 8%;
  }
}

//Bloc-Background
.bg-full-width {
  width: calc(100vw - var(--scrollbar-width));
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  border-radius: 0;
  padding: 2rem;

  & > .row {
    max-width: 1140px;
    margin: auto;
  }
}
//fill-available
.fill-available {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

// offer and candidacy
//////////////////////
.offer-detail-item, .candidacy-box {
  .offer-detail-know-before-apply {
    background-color: $light-cyan;
    border-radius: 10px;
    padding: 2rem;
    margin-top: 2.5rem;
    color: $black;


    &-info {
      margin-top: 0.5rem;
      text-align: left;
      font-weight: 500;
      line-height: 1.125rem;

      .row {
        margin-bottom: 10px;
      }

      &-value {
        color: $cyan;
      }
    }

    &-task {
      text-align: left;
      color: $black;
      margin-top: 2rem;

      &-label {
        font-size: 1.25rem;
        font-weight: bold;
      }

      &-value {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        margin-top: 1rem;
        padding: 0;

        .task-item {
          text-align: center;
          padding: 0.75rem 2.5rem;
          font-size: 0.75rem;
          font-weight: 500;
          background-color: $white;
          border-radius: 5px;
          margin: 1rem;
        }
      }
    }

    @include media-breakpoint-down(md) {
      &-icon, &-info {
        margin: 1.25rem 0;
      }
    }
  }
}

// Toasts
/////////
.copy-on-click-card {
  position: relative;
  .toast {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $white;
    color: $black;
  }

  &:hover {
    box-shadow: inset 6px 6px 12px #00000055;
    cursor: pointer;
  }
}

// scat sqaure icon btn
///////////////////////
.subcategory-square-icon-btn {
  display: flex;
  justify-content: center;
  background-color: $white;
  width: 56px;
  height: 56px;
  padding: 1rem;
  margin: 0.5rem;
  border: none;
  border-radius: 1rem;
  box-shadow: $default-shadow;
  cursor: pointer;

  i {
    font-size: 1.5rem;
    color: $electric-blue;
  }

  &.selected {
    cursor: default;
  }
}


.subcategories_list {

  &_no_result {
    font-weight: bold;
  }

  & > button {
    @extend .btn;
    @extend .btn-white;
    @extend .btn-max-rounded;
    @extend .shadowed;
    display: flex !important;
    padding: 0.375rem 0.75rem !important;
    flex-direction: row;
    align-items: center;
    margin: .5rem;
    border: none;
    cursor: unset;
    background-color: $white;
    color: $black;

    span:first-child {
      display: inline-block;
      min-width: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      background-color: $testimony-blue-op20;
      margin-right: 0.5rem;
      i {
        line-height: 2.4rem;
        font-size: 1.4rem;
        color: $presta-color;
      }
    }

    span {
      display: inline-block;
    }

    span:nth-child(2) {
      margin: auto;
    }

    &.selected, &:hover {
      background-color: $testimony-blue-op20;
      color: $black;
      span:first-child {
        background-color: $white;
      }
    }
  }
}

.btn.btn-facebook, .btn.btn-google {
  max-width: 100%;
  font-weight: 300;
  text-transform: unset;
  background-color: #f8f9fa;
  color: black;
  border: 2px solid $testimony-blue-op20;

  img {
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: $testimony-blue-op20;
  }
}

//social-medias-btn
.medias-circle {
  height: 40px;
  width: 40px;
  background-color: black;
  border-radius: 50%;
  border: 1px solid $black;
  color: white;
  padding: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: transparent;
    border: 1px solid #2653FB;
    color: #2653FB;
  }
}